import React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

function Login({ handleLogin, isLoggedIn, ReturnLogin }) {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(password);
  };

  if (isLoggedIn) {
    return <Navigate to='/' />;
  }
  return (
    <div className='h-screen w-screen flex'>
      <div className=' bg-white w-96 rounded-lg mx-auto my-auto overflow-hidden shadow-xl p-10'>
        <div>
          <img src='catalog_icon.png' alt='' className='w-auto h-12 mx-auto' />
        </div>
        <div className='block'>
          <div className='flex border bg-white border-gray-400 rounded-xl shadow-sm mx-auto mt-8'>
            <input
              type='password'
              placeholder='Masukan Password'
              id='input-text'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-full px-4 py-2 text-gray-700 rounded-xl focus:outline-none mx-auto'
            />
          </div>
          <div className='block '>
            <button
              type='submit'
              onClick={handleSubmit}
              className='flex mx-auto mt-2 w-full bg-[#312463] hover:bg-[#221a41] rounded-lg'
            >
              <div className='py-2 px-4 flex w-auto mx-auto'>
                <h1 className='text-white text-center font-semibold  mx-auto'>
                  Login
                </h1>
              </div>
            </button>
            <h1 className='text-center text-red-700 mt-2'>{ReturnLogin}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
