import React from 'react';

function Header({
  handleChange,
  ValueSearch,
  RequestSearch,
  handleKeyPress,
  id_type,
  handleLogout,
}) {
  return (
    <div className='px-2 py-2 sm:px-20 sm:py-5 bg-white  shadow-xl sm:flex sm:justify-between w-auto sticky top-0 z-10'>
      <div className='sm:w-1/2  sm:my-auto sm:mt-0 mx-auto mt-2'>
        <a href={`/?id_type=${id_type}`}>
          <img
            src='catalog_icon.png'
            alt=''
            className='w-auto h-8 my-auto mx-auto mb-5 sm:w-auto sm:h-12 sm:my-auto sm:mx-0 sm:mb-0'
          />
        </a>
      </div>
      <div className='w-full flex justify-end sm:w-1/2 '>
        <div className='w-full justify-end flex '>
          <div className='flex border bg-white border-gray-400 rounded-xl shadow-sm sm:w-2/3 w-4/5'>
            <input
              type='text'
              placeholder='Cari produk / size ...'
              id='input-text'
              className='w-full px-4 py-2 text-gray-700 rounded-xl focus:outline-none'
              value={ValueSearch}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
            <button onClick={RequestSearch}>
              <img
                src='search.png'
                alt=''
                className='h-5 w-auto mr-5 hover:h-6'
              />
            </button>
          </div>
          <div className='ml-2 py:ml-5 flex justify-center items-center'>
            <button
              onClick={handleLogout}
              className='flex items-center justify-center p-2'
            >
              <img
                src='logout.png'
                alt='Search Icon'
                className='h-5 w-auto hover:h-6'
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
