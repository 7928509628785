import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ProductCard from '../component/ProductCard';
import Header from '../component/Header';

function Catalog({ BaseUrl, id_type, handleLogout }) {
  const [Products, setProducts] = useState([]);
  const [ValueSearch, setValueSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const GetProducts = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/products?id_type=${id_type}`
      );
      setProducts(response.data.data);
      console.log(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setValueSearch(event.target.value);
  };

  const RequestSearch = async () => {
    console.log(ValueSearch);
    const response = await axios.get(
      `${BaseUrl}/search?article=${ValueSearch}&id_type=${id_type}`
    );
    setProducts(response.data.data);
    console.log(response.data.data);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      RequestSearch();
    }
  };

  useEffect(() => {
    GetProducts();
  }, [id_type, BaseUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Header
        handleChange={handleChange}
        ValueSearch={ValueSearch}
        RequestSearch={RequestSearch}
        handleKeyPress={handleKeyPress}
        id_type={id_type}
        handleLogout={handleLogout}
      />
      <div className='bg-slate-300 min-h-screen flex justify-center items-center'>
        <div className='flex flex-wrap justify-center w-full'>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            Products.map((item) => <ProductCard key={item.id} item={item} />)
          )}
        </div>
      </div>
    </div>
  );
}

export default Catalog;
