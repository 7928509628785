import React from 'react';

function ProductCard({ item }) {
  const formatCurrency = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    })
      .format(number)
      .replace(/\D00(?=\D*$)/, ''); // Menghilangkan desimal jika 00
  };

  return (
    <div className='m-2 w-40 sm:w-80 sm:m-5 flex flex-col'>
      <div className='rounded-lg bg-white overflow-hidden shadow-xl flex-grow flex flex-col'>
        <div className=' flex w-full sm:h-72 h-28 bg-white'>
          <img
            src={item.thumbnail ? item.thumbnail : 'shoes.webp'}
            alt={item?.article?.toUpperCase()}
            className='h-auto w-full my-auto'
          />
        </div>
        <div className='sm:p-6 p-3 flex flex-col flex-grow'>
          <div className='sm:h-16 h-32'>
            <h1 className='sm:text-base text-sm font-bold '>
              {item?.article?.toUpperCase()}
            </h1>
          </div>
          <div className='flex mt-2'>
            <div className='bg-[#312463] px-4 py-1 rounded-full mt-2'>
              <h1 className='sm:text-md text-sm font-semibold text-white '>
                {formatCurrency(item.price)}
              </h1>
            </div>
          </div>
          <div>
            <div className='grid grid-cols-5 px-2 py-1 mt-5'>
              <h1 className='col-span-4 text-left font-bold'>Size</h1>
              <h1 className='col-span-1 text-right font-bold'>Qty</h1>
            </div>
          </div>
          <div className='flex-grow'>
            {item?.detail?.map((itemdetail, index) => (
              <div key={index}>
                <div className='grid grid-cols-5 bg-[#D8D2EF] rounded-lg border-solid border-2  px-2 py-1 my-2'>
                  <h1 className='col-span-4 text-left sm:text-md text-sm font-semibold'>
                    {itemdetail.size}
                  </h1>
                  <h1 className='col-span-1 text-right sm:text-md text-sm my-auto  font-semibold'>
                    {itemdetail.qty}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
