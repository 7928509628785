import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Catalog from './pages/Catalog';
import Login from './pages/Login';

function App() {
  const BaseUrl = 'https://apicatalog.sidehustle.id';
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [returnLogin, setReturnLogin] = useState('');
  const [idType, setIdType] = useState('');

  const setWithExpiry = (key, value, ttl, idType) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
      idType: idType,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    // Periksa apakah item sudah kadaluwarsa
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item;
  };

  const handleLogin = async (password) => {
    try {
      const response = await fetch(`${BaseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });
      const data = await response.json();
      if (data.success) {
        setIdType(data.idtype);
        setIsLoggedIn(true);
        setWithExpiry('isLoggedIn', 'true', 3600000, data.idtype);
      } else {
        setReturnLogin('Password Salah, Coba lagi');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    <Navigate to='/' />;
  };

  useEffect(() => {
    const loggedInItem = getWithExpiry('isLoggedIn');
    if (loggedInItem) {
      setIsLoggedIn(loggedInItem.value === 'true');
      setIdType(loggedInItem.idType);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className='bg-slate-300 min-h-screen'>
        <Routes>
          <Route
            path='/'
            element={
              isLoggedIn ? (
                <Catalog
                  BaseUrl={BaseUrl}
                  id_type={idType}
                  handleLogout={handleLogout}
                />
              ) : (
                <Navigate to='/admin' />
              )
            }
          ></Route>
          <Route
            path='/admin'
            element={
              <Login
                BaseUrl={BaseUrl}
                handleLogin={handleLogin}
                isLoggedIn={isLoggedIn}
                returnLogin={returnLogin}
              />
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
